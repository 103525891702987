<template>
  <div class="oldBrand-add">
    <GlobalChunk icon="add" :title="titleDetail" :padding="[32, 0, 95, 50]">
      <el-row>
        <el-col :span="10">
          <el-form ref="ruleform" :model="initData" :rules="formRules">
            <el-form-item label="商户类型" prop="accountType">
              <el-radio-group
                v-model="initData.accountType"
                @change="typeChange"
              >
                <el-radio label="merchant">回收商</el-radio>
                <el-radio label="company">门店商</el-radio>
              </el-radio-group>
            </el-form-item>
            <div class="name_merchat">
              <el-form-item
                label="门店商家"
                :prop="initData.accountType == 'company' ? 'companyId' : ''"
                v-if="initData.accountType == 'company'"
              >
                <el-select
                  ref="select1"
                  v-model="initData.companyId"
                  placeholder="请输入查询或选择门店商家"
                  filterable
                  clearable
                  @change="handleChangeCompany"
                  @hook:mounted="cancalReadOnly"
                  @visible-change="cancalReadOnly"
                >
                  <el-option
                    v-for="item in merchantList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="choose_moneyAcc">
              <el-form-item
                label="选择资金账户"
                :prop="
                  initData.accountType == 'company' ? 'operationAccount' : ''
                "
                v-if="initData.accountType == 'company'"
                style="display: flex; align-items: center"
              >
                <el-radio-group
                  style="display: flex"
                  v-model="initData.operationAccount"
                  @change="accountChange"
                >
                  <el-radio label="01">回收账户</el-radio>
                  <el-radio label="02">换新账户</el-radio>
                  <el-radio label="03">差异基金账户</el-radio>
                </el-radio-group>
                <span
                  style="margin-left: 30px"
                  v-if="this.$route.query.type === 'sub'"
                  >当前账户余额:
                  {{
                    initData.operationAccount == "01"
                      ? companyAccountBalance.balance
                      : initData.operationAccount == "02"
                      ? companyAccountBalance.activityBalance
                      : companyAccountBalance.diffFundBalance
                  }}元
                </span>
              </el-form-item>
            </div>
            <div class="name_merchat">
              <el-form-item
                v-if="initData.accountType == 'merchant'"
                label="回收商家"
                prop="merchantId"
              >
                <el-select
                  ref="select1"
                  v-model="initData.merchantId"
                  placeholder="请输入查询或选择回收商家"
                  filterable
                  clearable
                  @change="handleChange"
                  @hook:mounted="cancalReadOnly"
                  @visible-change="cancalReadOnly"
                >
                  <el-option
                    v-for="item in shanpsSelectList"
                    :key="item.merchantId"
                    :label="item.merchantName"
                    :value="item.merchantId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="name_merchat">
              <el-form-item label="联系人" prop="contacts">
                <el-select
                  v-model="initData.contacts"
                  placeholder="请输入查询或选择联系人姓名"
                  filterable
                  clearable
                  @change="contactsHandleChange"
                >
                  <el-option
                    v-for="item in staffList"
                    :key="item.staffId"
                    :label="item.staffName"
                    :value="item.staffId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="name_merchat">
              <el-form-item label="联系电话" prop="contactsPhone">
                <el-input
                  placeholder="请输入联系电话"
                  v-model.trim="initData.contactsPhone"
                ></el-input>
              </el-form-item>
            </div>
            <div class="monut">
              <el-form-item
                :label="accountType == 'add' ? '上账金额' : '下账金额'"
                prop="amountPrice"
              >
                <el-input
                  style="width: 425px; display: flex; flex: 1"
                  :placeholder="
                    accountType == 'add'
                      ? '请输入上账（充值）金额'
                      : '请输入下账（扣减）金额'
                  "
                  @input="limitInput($event, 'amountPrice')"
                  v-model.trim="initData.amountPrice"
                ></el-input>
                <span
                  v-if="NumMoney"
                  style="color: #f83232; font-weight: bold"
                  >{{ NumMoney }}</span
                >
              </el-form-item>
            </div>
            <div class="name_merchat" v-if="accountType == 'add'">
              <el-form-item label="收款方式" prop="payeeWay">
                <el-select
                  ref="select1"
                  v-model="initData.payeeWay"
                  placeholder="请选择收款方式"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in payeeWayList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="name_merchat">
              <el-form-item
                :label="accountType == 'add' ? '收款凭证' : '截图凭证'"
                prop="voucher"
              >
                <JudgementsList
                  :is-reset="isResponsibilityReset"
                  :source-data="initData.voucher"
                  @dataChange="(v) => judgementsChange(v)"
                  :assignmentData="assignmentData"
                  :state="imgdetailData.state"
                />
               
              </el-form-item>
              <p class="el-upload__tip">{{accountType == 'add'
                      ? '请上传本次的收款凭证（限3张内，大小不超过5M，格式为JPG或PNG）'
                      : '请上传本次的截图凭证（限3张内，大小不超过5M，格式为JPG或PNG）'}}</p>
              <el-form-item
                :label="
                  accountType == 'add' ? '上账原因（非必填）' : '下账原因'
                "
                :prop="accountType != 'add' ? 'cause' : null"
              >
                <!-- :prop="accountType == 'sub' ? 'cause' : null" -->
                <el-input
                  maxlength="200"
                  type="textarea"
                  placeholder="请输入原因说明（限200字）"
                  v-model="initData.cause"
                ></el-input>
              </el-form-item>
            </div>
            <el-form-item>
              <el-button @click="FromBack">返回</el-button>
              <el-button type="primary" @click="handleConfirm('ruleform')"
                >确认提交</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </GlobalChunk>
    <!-- 动态口令弹出框 -->
    <el-dialog
      :title="commandTitle"
      :visible.sync="commandShow"
      :close-on-click-modal="false"
      width="410px"
      @closed="commandClosed"
    >
      <div class="sure-tip" v-if="accountType == 'add'">
        <span>请仔细核对上账金额</span>
      </div>
      <div class="sure-tip" v-else><span>请仔细核对下账金额</span></div>
      <div class="amount-price">
        <span>{{ accountType == "add" ? "上账" : "下账" }}金额：</span
        ><span>{{ params.amountPrice }}</span>
      </div>
      <div class="command">
        <div style="width: max-content">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请使用谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandClosed">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="submit"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import JudgementsList from "../componets/JudgementsList.vue";
import { formValidateMobileLandline } from "@/utils/form-validate";
import _api from "@/utils/request";
export default {
  name: "oldBrand-add",
  components: { JudgementsList },
  data() {
    return {
      imgdetailData: {
        cview: {
          auditStaffInfo: "",
        },
        state: "00",
      },
      assignmentData: [],
      isResponsibilityReset: false,
      companyAccountBalance: {
        balance: 0,
        activityBalance: 0,
        diffFundBalance: 0,
      },
      butLoading: false,
      titleDetail: "",
      initData: {
        accountType: "merchant",
        companyId: "",
        operationAccount: "01",
        merchantId: "",
        contacts: "",
        contactsPhone: "",
        amountPrice: "",
        voucher: [],
        cause: "",
        payeeWay: "私卡转账",
      },
      commandTitle: "",
      commandShow: false,
      accountType: "",
      command: "",
      merchantList: [],
      shanpsSelectList: [],
      staffList: [],
      params: {},
      NumMoney: "",
      payeeWayList: [
        {
          value: "私卡转账",
          label: "私卡转账",
        },
        {
          value: "民生银行内充",
          label: "民生银行内充",
        },
        {
          value: "建行内充",
          label: "建行内充",
        },
        {
          value: "招行内充",
          label: "招行内充",
        },
        {
          value: "交通银行内充",
          label: "交通银行内充",
        },
        {
          value: "平安银行内充",
          label: "平安银行内充",
        },
        {
          value: "授权调账",
          label: "授权调账",
        },
      ],
      formItemList: [
        { slotName: "accountType" },
        { slotName: "companyId" },
        { slotName: "operationAccount" },
        { slotName: "merchantId" },
        { slotName: "contacts" },
        {
          key: "contactsPhone",
          type: "input",
          labelName: "联系电话",
          placeholder: "请输入联系电话",
        },
        { slotName: "amountPrice" },
        {
          key: "payeeWay",
          type: "select",
          labelName: "收款方式",
          placeholder: "请选择收款方式",
          option: [
            {
              value: "私卡转账",
              label: "私卡转账",
            },
            {
              value: "民生银行内充",
              label: "民生银行内充",
            },
            {
              value: "建行内充",
              label: "建行内充",
            },
            {
              value: "招行内充",
              label: "招行内充",
            },
            {
              value: "交通银行内充",
              label: "交通银行内充",
            },
            {
              value: "平安银行内充",
              label: "平安银行内充",
            },
            {
              value: "授权调账",
              label: "授权调账",
            },
          ],
        },
        {
          key: "voucher",
          type: "uploadGroup",
          labelName: "收款凭证",
          placeholder:
            "请上传本次的收款凭证（限3张内，大小不超过5M，格式为JPG或PNG）",
        },
        { slotName: "cause" },
      ],

      formRules: {
        accountType: [
          {
            required: true,
            trigger: "blur",
            message: "选择商户类型",
          },
        ],
        companyId: [
          {
            required: true,
            trigger: "change",
            message: "选择门店商家",
          },
        ],
        operationAccount: [
          {
            required: true,
            trigger: "blur",
            message: "选择商家资金账户",
          },
        ],
        merchantId: [
          {
            required: true,
            trigger: "change",
            message: "选择回收商家",
          },
        ],
        contacts: [
          {
            required: true,
            trigger: "change",
            message: "输入联系人姓名",
          },
        ],
        contactsPhone: [
          {
            required: true,
            trigger: "blur",
            message: "输入联系电话",
          },
          {
            required: true,
            validator: formValidateMobileLandline,
            trigger: "blur",
          },
        ],
        amountPrice: [
          {
            required: true,
            trigger: "blur",
            message: "输入金额",
          },
        ],
        payeeWay: [
          {
            required: true,
            trigger: "blur",
            message: "输入收款方式",
          },
          {
            max: 20,
            trigger: "change",
            message: "限制20字内",
          },
        ],
        voucher: [
          {
            required: true,
            trigger: "change",
            message: "请上传图片",
          },
        ],
        cause: [
          {
            required: true,
            trigger: "blur",
            message: "输入下账原因（200字内）",
          },
        ],
      },
    };
  },
  created() {
    this.getMerchantList();
    this.getMerchantSelect();
    console.log(this.changeNumMoneyToChinese(2001.22));
  },
  mounted() {
    this.accountType = this.$route.query.type;
    if (this.$route.query.type === "add") {
      this.titleDetail = "商户余额上账";
      this.formItemList = [
        { slotName: "accountType" },
        { slotName: "companyId" },
        { slotName: "operationAccount" },
        { slotName: "merchantId" },
        { slotName: "contacts" },
        {
          key: "contactsPhone",
          type: "input",
          labelName: "联系电话",
          placeholder: "请输入联系电话",
        },
        { slotName: "amountPrice" },
        {
          key: "payeeWay",
          type: "select",
          labelName: "收款方式",
          placeholder: "请选择收款方式",
          option: [
            {
              value: "私卡转账",
              label: "私卡转账",
            },
            {
              value: "民生银行内充",
              label: "民生银行内充",
            },
            {
              value: "建行内充",
              label: "建行内充",
            },
            {
              value: "招行内充",
              label: "招行内充",
            },
            {
              value: "交通银行内充",
              label: "交通银行内充",
            },
            {
              value: "平安银行内充",
              label: "平安银行内充",
            },
            {
              value: "授权调账",
              label: "授权调账",
            },
          ],
        },
        {
          key: "voucher",
          type: "uploadGroup",
          labelName: "收款凭证",
          placeholder:
            "请上传本次的收款凭证（限3张内，大小不超过5M，格式为JPG或PNG）",
        },
        { slotName: "cause" },
      ];
      this.formRules.payeeWay = [
        {
          required: true,
          trigger: "change",
          message: "请选择收款方式",
        },
      ];
    } else {
      this.titleDetail = "商户余额下账";
      this.formItemList = [
        { slotName: "accountType" },
        { slotName: "companyId" },
        { slotName: "operationAccount" },
        { slotName: "merchantId" },
        { slotName: "contacts" },
        {
          key: "contactsPhone",
          type: "input",
          labelName: "联系电话",
          placeholder: "请输入联系电话",
        },
        { slotName: "amountPrice" },
        // {
        //   key: "payeeWay",
        //   type: "input",
        //   labelName: "付款方式",
        //   placeholder: "请输入付款方式(20个字以内)",
        // },
        {
          key: "voucher",
          type: "uploadGroup",
          labelName: "截图凭证",
          placeholder:
            "请上传本次的截图凭证（限3张内，大小不超过5M，格式为JPG或PNG）",
        },
        { slotName: "cause" },
      ];
      this.formRules.payeeWay = [
        {
          required: true,
          trigger: "blur",
          message: "请输入付款方式",
        },
        {
          max: 20,
          trigger: "blur",
          message: "限制20字内",
        },
      ];
    }
  },

  methods: {
    // 凭证
    judgementsChange(v) {
      console.log(v, "凭证");
      this.initData.voucher = v;
    },
    // 上传图片后，将图片信息保存到对应参数中
    handleUploadGroupChange(list) {
      console.log(list, this.initData.payVoucherImg);
    },
    typeChange(e) {
      this.initData.contacts = "";
      this.initData.contactsPhone = "";
    },
    accountChange(e) {
      console.log("1111", e);
      this.getCompanyAccount(this.initData.companyId);
    },
    //查看门店详细信息
    getCompanyAccount(id) {
      _api.getCompanyAccount({ companyId: id }).then((res) => {
        if (res.code === 1) {
          this.companyAccountBalance = {
            balance: res.data.companyAccount.balance,
            activityBalance: res.data.companyAccount.activityBalance,
            diffFundBalance: res.data.companyAccount.diffFundBalance,
          };
        }
      });
    },
    handleConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.initData.voucher.filter((item) => !item.url).length > 0) {
            return this.$message.error("请上传凭证！");
          }
          this.params = { ...this.initData };
          this.params.voucher = this.params.voucher.map((item) => item.url);
          if (this.accountType == "sub") {
            this.params.cause = this.initData.cause;
            this.commandTitle = "下账确认";
          } else {
            this.commandTitle = "上账确认";
          }
          this.commandShow = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 获取回收商
    getMerchantList() {
      const params = {
        pageNum: 0,
        pageSize: 999,
      };
      _api.listSimpleMerchantView(params).then((res) => {
        if (res.code === 1) {
          this.shanpsSelectList = res.data;
        }
      });
    },
    //获取门店商家下拉
    getMerchantSelect() {
      const params = {
        pageNum: 0,
        pageSize: 999,
      };
      _api.getSelectList(params).then((res) => {
        if (res.code === 1) {
          this.merchantList = res.data;
        }
      });
    },
    handleChangeCompany(val) {
      console.log(777, val);
      this.initData.companyId = val;
      this.initData.contacts = "";
      this.initData.contactsPhone = "";
      if (val) {
        this.manageStaffSelect();
        //查看门店当前账户
        this.getCompanyAccount(val);
      } else {
        this.staffList = [];
      }
    },
    handleChange(val) {
      this.initData.merchantId = val;
      this.initData.contacts = "";
      this.initData.contactsPhone = "";
      if (val) {
        this.getStaffList();
      } else {
        this.staffList = [];
      }
    },
    contactsHandleChange(val) {
      if (val) {
        this.staffList.forEach((item) => {
          if (item.staffId == val) {
            this.initData.contacts = item.staffName;
            this.initData.contactsPhone = item.staffPhone || item.staffMobile;
          }
        });
      } else {
        this.initData.contacts = "";
        this.initData.contactsPhone = "";
      }
    },
    FromBack() {
      this.$router.go(-1);
    },
    commandClosed() {
      this.commandShow = false;
      this.butLoading = false;
      this.command = "";
    },
    submit() {
      let params = this.params;
      params.command = this.command;
      this.butLoading = true;
      if (this.accountType == "sub") {
        // _api.subAccount(params).then((res) => {
        _api
          .subMerchantAccount(params)
          .then((res) => {
            if (res.code === 1) {
              this.$router.push({ path: "/payment/PaybAccount" });
              this.$message({
                message: "添加成功",
                type: "success",
              });
            } else {
              this.$message({
                message: "添加失败",
                type: "error",
              });
            }
            this.butLoading = false;
          })
          .catch((err) => {
            this.butLoading = false;
          });
      } else {
        // _api.addAccount(params).then((res) => {
        _api
          .addMerchantAccount(params)
          .then((res) => {
            if (res.code === 1) {
              this.$router.push({ path: "/payment/PaybAccount" });
              this.$message({
                message: "添加成功",
                type: "success",
              });
            } else {
              this.$message({
                message: "添加失败",
                type: "error",
              });
            }
            this.butLoading = false;
          })
          .catch((err) => {
            this.butLoading = false;
          });
      }
    },
    limitInput(value, name) {
      console.log(value);
      let str = (value && value.split("")) || [];
      let reg1 = /\d/;
      let reg2 = /\./;
      // 第一个字符不能为小数点
      if (str[0] == ".") {
        this.initData[name] = "";
        return;
      }
      // 过滤掉除数字和小数点外的字符
      value = str.filter((e) => reg1.test(e) || reg2.test(e));
      // 匹配小数点后只能有两位小数
      let valJoin = value.join("");
      this.initData[name] = valJoin.match(/^\d*(\.?\d{0,2})/g)[0] || null;
      this.NumMoney = valJoin.match(/^\d*(\.?\d{0,2})/g)[0]
        ? this.changeNumMoneyToChinese(valJoin.match(/^\d*(\.?\d{0,2})/g)[0])
        : "";
    },
    // 购买人（使用人）列表
    getStaffList() {
      const params = {
        merchantId: this.initData.merchantId,
      };
      _api.recycleStaffSelect(params).then((res) => {
        if (res.code === 1) {
          this.staffList = res.data;
        }
      });
    },
    // 门店商家下拉列表
    manageStaffSelect() {
      const params = {
        companyId: this.initData.companyId,
      };
      _api.manageStaffSelect(params).then((res) => {
        if (res.code === 1) {
          this.staffList = res.data;
        }
      });
    },
    changeNumMoneyToChinese(money) {
      var cnNums = new Array(
        "零",
        "壹",
        "贰",
        "叁",
        "肆",
        "伍",
        "陆",
        "柒",
        "捌",
        "玖"
      ); //汉字的数字
      var cnIntRadice = new Array("", "拾", "佰", "仟"); //基本单位
      var cnIntUnits = new Array("", "万", "亿", "兆"); //对应整数部分扩展单位
      var cnDecUnits = new Array("角", "分", "毫", "厘"); //对应小数部分单位
      var cnInteger = "整"; //整数金额时后面跟的字符
      var cnIntLast = "元"; //整型完以后的单位
      var maxNum = 999999999999999.9999; //最大处理的数字
      var IntegerNum; //金额整数部分
      var DecimalNum; //金额小数部分
      var ChineseStr = ""; //输出的中文金额字符串
      var parts; //分离金额后用的数组，预定义
      var Symbol = ""; //正负值标记
      if (money == "") {
        return "";
      }

      money = parseFloat(money);
      if (money >= maxNum) {
        alert("超出最大处理数字");
        return "";
      }
      if (money == 0) {
        ChineseStr = cnNums[0] + cnIntLast + cnInteger;
        return ChineseStr;
      }
      if (money < 0) {
        money = -money;
        Symbol = "负 ";
      }
      money = money.toString(); //转换为字符串
      if (money.indexOf(".") == -1) {
        IntegerNum = money;
        DecimalNum = "";
      } else {
        parts = money.split(".");
        IntegerNum = parts[0];
        DecimalNum = parts[1].substr(0, 4);
      }
      if (parseInt(IntegerNum, 10) > 0) {
        //获取整型部分转换
        var zeroCount = 0;
        var IntLen = IntegerNum.length;
        for (var i = 0; i < IntLen; i++) {
          var n = IntegerNum.substr(i, 1);
          var p = IntLen - i - 1;
          var q = p / 4;
          var m = p % 4;
          if (n == "0") {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              ChineseStr += cnNums[0];
            }
            zeroCount = 0; //归零
            ChineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m == 0 && zeroCount < 4) {
            ChineseStr += cnIntUnits[q];
          }
        }
        ChineseStr += cnIntLast;
        //整型部分处理完毕
      }
      if (DecimalNum != "") {
        //小数部分
        var decLen = DecimalNum.length;
        for (let i = 0; i < decLen; i++) {
          let n = DecimalNum.substr(i, 1);
          if (n != "0") {
            ChineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }
      }
      if (ChineseStr == "") {
        ChineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (DecimalNum == "") {
        ChineseStr += cnInteger;
      }
      ChineseStr = Symbol + ChineseStr;

      return ChineseStr;
    },
    cancalReadOnly(value) {
      this.$nextTick(() => {
        if (!value) {
          const { select1 } = this.$refs;
          const input = select1.$el.querySelector(".el-input__inner");
          input.removeAttribute("readonly");
          // this.$refs.select.blur();  根据tip自行判断是否添加
        }
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.oldBrand-add {
  .el_from {
    margin-left: -120px;
    margin-bottom: 0;
  }
  /deep/.el-select {
    width: 100% !important;
  }
  .command {
    display: flex;
    align-items: center;
    /deep/.el-input {
      width: 300px;
    }
  }
  .amount-price {
    color: #f59a23;
    font-size: 14px;
    margin: 15px 0;
  }
  .sure-tip {
    color: #0981ff;
    font-size: 16px;
    padding-left: 10px;
    border-left: 3px solid;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
/deep/.el-form-item {
  display: flex;
}
.name_merchat {
  /deep/.el-form-item__content {
    flex: 1;
    display: flex;
    align-items: center;
  }
}
.choose_moneyAcc {
  .el-form-item {
    width: 700px;
  }
  /deep/.el-form-item__content {
    display: flex;
    align-items: center;
  }
}
.el-upload__tip {
  margin: -10px 0 20px 90px;
}
</style>
